import React, { useState } from 'react';
import '../../style.scss';
import HeroBanner from './components/heroBanner/HeroBanner';
import Footer from '../../components/footer/footer';
import SideBar from '../../components/sideBar/sideBar';
import SearchBar from '../../components/searchBar/searchBar';
import entities from '../../entityData/iranianentities.json';
import HeaderLogo from '../../components/logo/logo';
import {ExampleSection, OurDataAmount, SanctionsEvaders} from './components/services/example_profiles';

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchButton, setSearchButton] = useState(true)
  const [isCaseSideBar, setCaseSideBar] = useState(false)

  return (
    <body className='bg-OffWhite overflow-hidden'>
      <header className='flex flex-row'>
        <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
        <HeaderLogo />
      </header>
      <article className=''>
        <section className='p-4 sm:h-screen' >
          <div className='items-center mx-auto max-w-[768px] mt-[200px]'>
            <HeroBanner />
            <SearchBar
                placeholder="Enter an entity name"
                data={entities}
                isOpen={isOpen}
                isSearchButton={isSearchButton}
                isCaseSideBar={isCaseSideBar}
             />
            
            <ExampleSection/>
            <OurDataAmount/>
             

          </div>
      
          <div>
          </div>
        </section>

        <SanctionsEvaders/>
      



   

    
      </article>
      <Footer />
    </body >

  );
}