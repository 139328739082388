import React, { useState, useEffect } from 'react';
import ResultsPage from '../pages/result/result';
import { useNavigate } from 'react-router-dom';
import data from '../entityData/iranianentities.json';



export function Search({ placeholder, isOpen, isSearchButton, isCaseSideBar}) {

    const [originalSearchQuery, setOriginalSearchQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [entities, setEntities] = useState([]);
   


    const handleFilter = (event) => {
        const searchWord = event.target.value.toLowerCase(); //Convert Search to lowercase
        setSearchQuery(searchWord);
        setOriginalSearchQuery(event.target.value); //Store original search query

        const newFilter = data.filter((value) => {
            return value.Entity.toLowerCase().includes(searchWord);
        });

        setEntities(newFilter);
        console.log(newFilter)

    };

    const handleSearch = () => {
        setIsSearchClicked(true);
        console.log(isSearchClicked);
        navigate('/result', { state: { entities: entities } }); //Navigate to ResultsPage and pass filtered entities
    };

    const handleCreateCase = (entity) => {
        navigate('/company-profile', { state: { entity: entity } });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };



    return (
    
        <div id="search" className={`${isCaseSideBar ? ' w-[200px] md:w-[270px] xl:w-[320px]' : ':w-2/3 sm:max-w-[768px] '}`}>
            <div id='searchInputs' className='flex flex-row'>
                <div className='border-box box min-w-[150px] w-[768px] max-w-[768px] shadow-xl'>
                    <input className='p-1 text-black w-full h-full rounded-xl border-none outline-none pl-3'
                            type="Search entity"
                            placeholder={placeholder}
                            value={originalSearchQuery}
                            onChange={handleFilter}
                            onKeyDown={handleKeyDown}  />
                       </div>
             
       
     {isSearchButton && (
               <button
                       onClick={handleSearch}
                  className={`p-5 shadow-xl right-7 h-full text-sm sm:text-xl bg-yellow-500 rounded-r-lg relative text-white ${isOpen ? 'invisible md:visible' : ''}`}> Search
                  </button>
                  
                  
        ) }
        </div>

        </div>

    );
}

