import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/index';
import ResultsPage from './pages/result/index';
import CompanyProfilePage from './pages/companyProfile/companyProfilePage';
import AboutUsPage from './pages/about/index';
import OurDataPage from './pages/our_data';
import './dist/styles/output.css'

export default function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/result" element={<ResultsPage />} />
                    <Route path="/company-profile" element={<CompanyProfilePage />} />
                    <Route path='/about-us' element={<AboutUsPage />} />
                    <Route path='/our-data' element={<OurDataPage />} />
                </Routes>
            </Router>
        </div>
    )
}