import React from 'react';
import ReactDOM from 'react-dom/client';
import '../../style.scss';
import ResultsPage from './result'
import NavBackButton from '../../components/navback/backbutton'
import CaseSideBar from '../../components/sideBar/caseSideBar';


export default function Result() {

  return (


    <body className='bg-OffWhite'>
      <header className='absolute'>
        <CaseSideBar />
      </header>
      <main>
        <article className='grid h-screen grid-cols-12 p-20 overflow-scroll '>
          <h1 className='col-start-1 sm:col-start-2 col-end-12  mb-5 text-xl sm:text-3xl'>Case Management</h1>
          <div className='col-start-12'>
            <NavBackButton toPage='home' name='Back to home' />
          </div>
          <section className='col-start-1 md:col-start-3 col-end-12'>
            <ResultsPage />
          </section>

        </article>
      </main>


    </body >

  );

}