import React from "react";



export default function SideBar({ isOpen, setIsOpen }) {


    const handleGoHome = () => {
        window.location.href = '/';
    };
    const handleGoOurData = () => {
        window.location.href = '/our-data';
    };

    const handleGoAboutUs = () => {
        window.location.href = '/about-us';
    };

    const navigateOurDataDashboard = () => {
        window.open("https://lookerstudio.google.com/reporting/be42cea8-5ea0-49e0-a6ee-9b20c29f33b7", "_blank")
    }


    function navigatetoTrello() {
        window.open("https://trello.com/b/CvHWr2Sx/knowyoursanctions-roadmap", "_blank"); // Replace with your desired URL
    }


    return (

        <>

            {!isOpen ?
                (

                    <nav className="pl-11 pt-11 flex flex-col fixed z-0 h-screen items-center capitalize">

                        <div className="flex flex-row">
                            <button onClick={() => setIsOpen(!isOpen)}>
                                <span class="material-symbols-outlined"> menu </span>
                            </button>

                        </div>


                    </nav>

                ) :

                (

                    <nav className={`p-11 flex flex-col fixed z-0 justify-center w-screen md:justify-normal md:w-fit h-screen items-center capitalize bg-primary text-white ease-in-out duration-300
 ${isOpen ? 'translate-x-0' : 'translate-x-0'} `}>

                        <button className="text-xl"
                            onClick={() => setIsOpen(!isOpen)} >
                            <span class="material-symbols-outlined">cancel</span>
                        </button>



                        <ul className="mt-11 text-md text-xl ease duration-400">

                            <li onClick={handleGoHome} className="navbar_item">
                                <span class="material-symbols-outlined">home</span>
                                <p className="navbar-hover">Home </p></li>

                            <li className="navbar_item" onClick={handleGoAboutUs}>
                                <span class="material-symbols-outlined"> quiz </span>
                                <p className="navbar-hover">About us</p>
                            </li>

                            <li className="navbar_item">
                                <span class="material-symbols-outlined" onClick={navigatetoTrello}>map</span>
                                <p className="navbar-hover" onClick={navigatetoTrello}>Roadmap</p>


                            </li>


                            <li className="navbar_item" onClick={navigateOurDataDashboard} >
                                <span class="material-symbols-outlined">database</span>
                                <p className="navbar-hover" onClick={navigateOurDataDashboard}>Our data</p>

                            </li>
                        </ul>

                    </nav>

                )


            }


        </>


    );
}
