import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import '../../style.scss';
import SideBar from '../../components/sideBar/sideBar';
import Footer from '../../components/footer/footer';
import HeaderLogo from '../../components/logo/logo';


export default function AboutUsPage() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <body className='bg-OffWhite'>
            <header>
                <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
                <HeaderLogo />
            </header>
            <article className='ml-14 pt-11 grid grid-cols-12'>
                <section className='mt-11 mb-11 col-start-2 col-end-11 sm:col-start-4 sm:col-end-9'>
                    <h1 className='mt-11 text-4xl sm:text-5xl'>Who we are</h1>

                    <p className='text-2xl mt-5'>

                        At Know Your Sanctions, our mission is to uncover the networks surrounding individuals and companies engaged in conflicts and transnational crime.
                    </p>

                    <p className='text-2xl mt-5'>
                        We are a small team of researchers and developers dedicated to preventing financial crime and revealing the key individuals behind conflicts.
                    </p>


                    <h1 className='text-4xl sm:text-5xl mt-11'>What is Disclose?</h1>
                    <p className=' text-2xl mt-5 leading leading'>
                        Disclose is an international database
                        of persons and companies of political and economic interest involved in
                        transnational crime or on sanctions and watchlists.

                    </p>
                    <p className='text-2xl mt-5'>
                        Our data combines sanctions lists, watchlists, Politically Exposed People, suppliers
                        and vessels and entities invovled in conflicts, corruption, and the violation of
                        human rights.
                    </p>
                    <h1 className='mt-11 text-4xl sm:text-5xl'>Why should I use Disclose?</h1>
                    <p className='text-2xl mt-5'>
                        Disclose consolidates sources to publish profiles of people and companies of interest, including politicians and
                        known criminals.
                    </p>






                    <section />


                </section>

            </article >
            <Footer />
        </body >

    );
}