import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CompanyResult from './companyresult';

const truncateText = (text, maxLength, blurLength) => {
  if (text.length <= maxLength) {
    return [text, '', '', false];
  }

  const visibleText = text.substring(0, maxLength);
  let lastSpaceIndex = visibleText.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
    lastSpaceIndex = maxLength; // No space found within maxLength, so use the entire visibleText length
  }

  const visibleTextTrimmed = visibleText.substring(0, lastSpaceIndex);
  const remainingText = text.substring(lastSpaceIndex).trim();

  let blurredText = '';
  if (remainingText.length > blurLength) {
    blurredText = remainingText.substring(0, blurLength) + '...';
  } else {
    blurredText = remainingText;
  }

  return [visibleTextTrimmed, blurredText, remainingText, true];
};

// The CompanyProfile Section contains the companyResult data for each category of data and passed to companyProfile page.
const CompanyProfileSection = () => {
  const location = useLocation();
  const entity = location.state?.entity || null;
  const maxLength = 500;
  const blurLength = 150;
  const [expanded, setExpanded] = useState(false);

  {/* If data is not found, we do not render any data. Instead, we display a loading state. */ }


  if (!entity) {
    return <div>Company data not found...</div>; // Display a loading state if the company data is not available yet
  }

  {/*In this section we map our JSON data fields for firmographic section and nested expense data.*/ }


  const fields = [
    { heading: 'Name', key: ['Entity'] || ['NamesTitle'] },
    { heading: 'Original name', key: ['Name Non-Latin Script'] },
    { heading: 'Country', key: ['Country'] },
    { heading: 'Address', key: ['Address'] },
    { heading: 'Date of birth', key: ['DOB'] },
    { heading: 'Nationality', key: ['Nationality'] },
    { heading: 'Place of birth', key: ['Town of Birth'] },
    { heading: 'Country of Birth', key: ['Country of Birth'] },
    { heading: 'Passport Number', key: ['Passport Number'] },
    { heading: 'Passport Details', key: ['Passport Details'] },
    { heading: 'National Identification Number', key: ['National Identification Number'] },
    { heading: 'National Identification Details', key: ['National Identification Details'] },
    { heading: 'Official Position', key: ['Position'] },

    { heading: 'website', key: ['company_website'] },
    { heading: 'Weapon Program', key: ['Weapon Program'] },
    { heading: 'Linked entities', key: ['Suspected Links'] },
    { heading: 'Political Party', key: ['Party'] },
    { heading: 'Constituency', key: ['Membership'] },
    { heading: 'Start Date', key: ['start_date'] },
    { heading: 'End Date', key: ['end_date'] },
    { heading: 'Status', key: ['status_description'] },


  ];


  const sanction = [
    { heading: 'Sanction Regime', key: ['Regime'] },
    { heading: 'Listed On', key: ['Listed On'] },
    { heading: "Last Updated", key: ['Last Updated'] },
  ]

  const sanctionInformation = [
    {
      heading: 'Information', key: ['Other Information']
    }
  ]

  const expense = [
    { heading: 'Type', key: ['Expenses', 'type_expense'] },
    { heading: 'Registered Interest', key: ['Expenses', 'description'] }
  ];



  const getFieldData = (data, keys) => {
    return keys.reduce((obj, key) => obj && obj[key], data);
  };

  {/*In this section we define our intelligence data with information const. We also defined visible and blurred text variables.*/ }


  const information = entity["Information"];
  const [visibleText, blurredText, remainingText, isTextTruncated] = truncateText(
    information || '',
    maxLength,
    blurLength
  );
  const toggleExpand = () => {
    setExpanded(!expanded);
  };


  return (
    <>

      {/*In this section we render firmographic details about an entity. Empty fields are never rendered. */}


      <section className='flex flex-col sm:grid sm:grid-cols-2 gap-5 border-b-2'>
        {fields.map((field) => {
          const fieldData = getFieldData(entity, field.key);
          return fieldData && (
            <CompanyResult key={field.key} heading={field.heading} data={fieldData} />
          );
        })}

      </section>

      {/*In this section we render sanctions details about an entity. Empty fields are never rendered. */}

      <section className='flex flex-col sm:grid sm:grid-cols-2 sm:gap-5 border-b-2'>
        {sanction && sanction.some((field) => getFieldData(entity, field.key)) && (
          <h1 className='text-2xl mt-11 mb-5 col-span-2'>Sanctions</h1>
        )}
        {sanction.map((field) => {
          const fieldData = getFieldData(entity, field.key);
          return fieldData && (
            <>
              <CompanyResult key={field.key} heading={field.heading} data={fieldData} />
            </>
          )

        })}
        <p className='col-span-2'>
          {sanctionInformation.map((field) => {
            const fieldData = getFieldData(entity, field.key);
            return fieldData && (
              <>
                <CompanyResult key={field.key} heading={field.heading} data={fieldData} />
              </>
            )

          })}
        </p>


      </section >


      {/*In this section we render PEP Expenses Data by mapping the Expense variable using getFieldData function */}

      < section className='flex flex-col' >
        {expense && expense.some((field) => getFieldData(entity, field.key)) && (
          <h1 className='text-2xl mt-2 mb-5'>Donors & Interests</h1>
        )
        }
        {
          expense.map((field) => {
            const fieldData = getFieldData(entity, field.key);
            return fieldData && (
              <>

                <CompanyResult key={field.key} heading={field.heading} data={fieldData} />

              </>
            );
          })
        }


      </section>


      {/*We render intelligence text if there is data. Otherwise the section is empty. We also blur part of the string when section is closed */}

      {information && information.length > 0 && (

        <section className='mt-11'>
          <div className='flex flex-col p-2'>

            {isTextTruncated && !expanded ? (
              <>
                <button className='flex flex-row gap-5 mb-5' onClick={toggleExpand}>
                  <h1 className='font-semibold mb-2'>Information</h1>
                  <span className='material-symbols-outlined flex items-center gap-1'>
                    {/*  <p className=' font-sans text-sm'>Show more</p> */}
                    expand_content
                  </span >
                </button >


                <p className='font-thin' onClick={toggleExpand}>{visibleText}{' '}
                  <span className='font-thin' style={{ filter: 'blur(1.5px)' }}>
                    {blurredText} </span>
                </p>
              </>
            ) : (
              <>
                <h1 className='font-semibold mb-5'>Intelligence</h1>
                <p className='font-thin'>
                  {expanded ? information : visibleText}

                </p>
              </>
            )}
            {
              isTextTruncated && expanded && (
                <button className='flex flex-row gap-5 mt-5' onClick={toggleExpand}>
                  <span className='material-symbols-outlined flex items-center gap-1'>
                    <p className='font-sans text-sm'>Show less</p>
                    expand_content
                  </span>
                </button>
              )
            }
          </div >
        </section >
      )}


    </>
  );
};

export default CompanyProfileSection;
