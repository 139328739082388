import React from "react";
import { useState } from "react";
import { Search } from '../../scripts/search'
import SearchBar from "../searchBar/searchBar";
import entities from '../../entityData/iranianentities.json';

export default function CaseSideBar({isSearchButton}) {
    const [isOpen, setIsOpen] = useState(false);
    const handleGoHome = () => {
        window.location.href = '/';

    };
    const [isCaseSideBar, setCaseSideBar] = useState(false)
    const searchJSX = Search({ placeholder: "Search", data: entities });

    return (

        <>

            {!isOpen ?
                (

                    <nav className="hidden md:block bg-primary p-11 text-white pl-11 pt-11 sm:flex sm:flex-col sm:fixed sm:z-0 sm:h-screen sm:items-center sm:capitalize">
                        <button onClick={() => setIsOpen(!isOpen)}>
                            <span class="material-symbols-outlined"> menu </span>
                        </button>
                        <div className="flex flex-row">

                            <ul className="mt-11 text-md text-xl ease duration-400">

                                <li onClick={handleGoHome} className="casebar_item cursor-pointer">
                                    <span class="material-symbols-outlined symbol">home</span>
                                </li>

                                <li className="casebar_item cursor-pointer">
                                    <span
                                        class="material-symbols-outlined symbol"
                                        onClick={() => setIsOpen(!isOpen)} >search </span>

                                </li>

                            </ul>


                        </div>


                    </nav>

                ) :

                (

                    <nav className={`p-4 flex flex-col fixed z-0 w-fit h-screen items-center capitalize bg-primary shadow-2xl text-white
 ${isOpen ? 'translate-x-0' : 'translate-x-0'} `}>

                        <button className="text-xl"
                            onClick={() => setIsOpen(!isOpen)} >
                            <span class="material-symbols-outlined">cancel</span>
                        </button>

                        <ul className="mt-11 text-md text-xl ease duration-400 ">

                            <li onClick={handleGoHome} className="casebar_item cursor-pointer">
                                <span class="material-symbols-outlined">home</span>
                                <p className="casebar-hover">Home </p></li>

                            <li className="casebar_item flex flex-col">

                                <div className='flex items-center pb-4 cursor-pointer'>
                                    <span class="material-symbols-outlined">search</span>
                                    <p className="casebar-hover">Search</p>
                                </div>
                                <div id="search" className=' box-border block cursor-text'>
                                    <div id='searchInputs' className='border-box flex flex-row'>
                                    <SearchBar
                                            placeholder="Enter an entity name"
                                            data={entities}
                                            isOpen={isOpen}
                                            isSearchButton={isSearchButton}
                                            isCaseSideBar={setCaseSideBar}
             
                                     />

                                           
                                                                                        

                                    </div>
                                </div>
                            </li>


                        </ul>

                    </nav >

                )


            }


        </>


    );
}
