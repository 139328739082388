import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../style.scss';

const ResultsPage = ({ handleCreateCase }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const entities = location.state?.entities || [];
  const handleCreateCaseClick = (entity) => {
    navigate('/company-profile', { state: { entity: entity } });
  };


  const itemsPerPage = 10; //Nr items to show
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedEntities, setDisplayedEntities] = useState([]);


  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const entitiesToDisplay = entities.slice(startIndex, endIndex);
    setDisplayedEntities(entitiesToDisplay);
  }, [entities, currentPage]);

  const totalPages = Math.ceil(entities.length / itemsPerPage);


  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(entities.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  return (
    <>


      <table className=' sm:p-11 sm:ml-11 table-fixed'>
        <thead>
          <tr>
            <th className='w-[200px] sm:w-[300px] md:w-[800px]'></th>
          </tr>
        </thead>
        <tbody className=' space-y-11'>

          {displayedEntities.map((entity) => (
            <tr className='' key={entity.Entity}>
              <td className='text-md md:text-2xl p-3'>{entity.Entity}</td>
              <td>
                <button onClick={() => handleCreateCaseClick(entity)} className='ml-11 text-xs md:text-sm bg-gray-100 shadow-md rounded-md p-3'>Create Case</button>
              </td>
            </tr>

          ))}
        </tbody>
      </table>

      <div className='flex justify-center mt-4 gap-4'>
        <button onClick={handlePrevPage} disabled={currentPage === 1} className='mr-2'>Previous</button>
        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(entities.length / itemsPerPage)}>Next</button>
        <p>Page {currentPage}/{totalPages}</p>
      </div>

    </>
  );
};

export default ResultsPage;