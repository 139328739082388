import React from 'react';




const NavBackButton = ({ name, toPage }) => {
    const handleGoBack = () => {
        window.history.back();
    };

    const handleGoHome = () => {
        window.location.href = '/';
    };

    const handleClick = () => {
        if (toPage == 'home') {
            handleGoHome();
        } else if (toPage == 'back') {
            handleGoBack();
        }
    }


    return (
        <div onClick={handleClick} className='flex items-center'>
            <span class="material-symbols-outlined">
                arrow_back_ios
            </span>
            <button className='  text-xs sm:text-sm font-sans'>{name}</button>
        </div>

    )
}
export default NavBackButton;
