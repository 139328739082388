import React from "react"
import '../../style.scss';


export default function HeaderLogo() {
    return (
        <>
            <h1 className="text-sm pt-11 pl-24 sm:text-2xl font-semibold text-primary font-">Know Your Sanctions</h1>
        </>
    )
};

