import React from 'react';
import ReactDOM from 'react-dom/client';
import '../../style.scss';
import SideBar from '../../components/sideBar/sideBar';
import Footer from '../../components/footer/footer';


export default function OurDataPage() {
  return (
    <body className='bg-OffWhite'>
      <header>
        <SideBar />
      </header>
      <article className='h-screen grid grid-cols-12 grid-rows-6'>
        <section className='row-start-2 col-start-2'>
          <h1 className='bg-primary text-white text-5xl p-3 rounded-md w-[200px] text-center'>Disclose</h1>
          <h1 className='p-3 rounded-xl text-5xl w-[200px] text-center'>Data</h1>
          <section />


        </section>

      </article >
      <Footer />
    </body >

  );
}