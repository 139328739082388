import React from "react"


function DesktopButtons(){
    return(

        <div className="flex flex-row gap-1">
            <div className="p-2 bg-red-500 rounded-full w-[3px]"></div>
            <div className="p-2 bg-yellow-500 rounded-full w-[3px]"></div>
            <div className="p-2 bg-green-500 rounded-full w-[3px]"></div>
        </div>
    )
}

export function Desktop(){
    return(

        <section className='ml-4 mr-4 h-[400px] shadow-2xl rounded-xl bg-primary p-4 mt-11'>
                <DesktopButtons/>
                <section className="mt-11 ml-2 mx-auto rounded-xl">
                    <p className="text-lime-100 text-md max-w-[630px]">
                    "Bank Meli" <br></br>
                    "Suspected Links" : ["Defense Industries Organization", "Revolutionary Guard"] <br></br>
                    "Sanctions" : "OFAC" <br></br>
                    "Country" : ['Iraq', 'Iran', 'Syria']
                    </p>

                    <p className="text-lime-100 mt-4 text-md">
                    "Defense  Industries Organization:" <br></br>
                    "Suspected Links": ["Bank Meli", "Ministry of Defense Armed Forces Logistics"] <br></br>
                    "Sanctions": " Executive Order 13382" <br></br>
                    "Country": "Iran"
                       
                    </p>

                </section>


        </section>


    )

}
