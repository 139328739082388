import React from 'react';
import { useLocation} from 'react-router-dom';
import CompanyProfileSection from './companyProfileSection';
import NavBackButton from '../../components/navback/backbutton'
import CaseSideBar from '../../components/sideBar/caseSideBar';

//The CompanyProfile Section contains the companyResult data for each category of data and passed to companyProfile page. 

const CompanyProfilePage = () => {



    const location = useLocation();
    const entity = location.state?.entity || null;
    return (
        <body className='bg-OffWhite'>
            <header className='mb-11 absolute'>
                <CaseSideBar />
            </header>
            <main className='h-screen'>
                <article className='p-11 grid grid-cols-12 bg-OffWhite'>
                    <div className='col-start-12'>
                        <NavBackButton toPage='back' name='Back to results' />
                    </div>
                    <section className='flex flex-col gap-2 col-start-3 text-3xl col-end-8 mb-11 border-b-2'>
                        <h1>{entity.Entity}</h1>
                        <p className='text-sm italic'>{entity['Group Type']}</p>
                    </section>

                    <section className='col-start-3 col-end-10 text-xl'>
                        <CompanyProfileSection />
                    </section>
                </article>

            </main>

        </body>


    );
};
export default CompanyProfilePage;