import React from 'react';
import {FaBan, FaConnectdevelop, FaExclamation, FaRegIdBadge, FaCheckCircle} from 'react-icons/fa';
import { Desktop } from './desktop';
import {TfiSearch} from 'react-icons/tfi'

//Define what our example profiles look like
function Example({icon, status, name, iconColour}){
    return(

    <div className='w-fit p-4 bg-white shadow-md rounded-xl flex flex-row gap-2 items-center'>
        <span className={iconColour}>{icon}</span>
        <h1>{status}</h1>
        <p>{name}</p>
    </div>

    );
}

//Create section with examples of different PEP profiles that users can search for
export function ExampleSection(){
    return(
<>
    <h1 className='mt-[150px] mb-4 text-center text-3xl '> 
    Screen Sanctions and PEPs
    </h1>

    <section className='flex flex-row gap-4 max-w-[768px] flex-wrap justify-center mb-11'>

        <Example
            icon={<FaExclamation/>}
            status={'PEP -'}
            name={'Anastasia Tolstoy'}
            iconColour={'text-red-900'}
            />

        <Example
            icon={<FaBan/>}
            status={'Sanctions -'}
            name={'Bank of Russia'}
            iconColour={'text-red-900'}
            />

    <Example
            icon={<FaExclamation/>}
            status={'PEP -'}
            name={'Dominic Raab'}
            iconColour={'text-red-900'}
            />
  
   

        </section>

</>



    )
}

//How much data we have - template
export function OurDataTemplate({amount, text}){
    return(
    <>
        <div className='flex flex-col'>
        <hr className='bg-black w-[50px] mb-2 border-t-black border-2'></hr>
            <h2 className='text-2xl font-bold'>{amount}</h2>
            <p className='min-w-[100px]'>{text}</p>
        </div>
        </>    
        )
}

//How much data do we have?
export function OurDataAmount(){
    return(

        <section className='mt-[100px] flex flex-row gap-4 max-w-[768px] flex-wrap justify-center mb-[200px]'>
            <OurDataTemplate amount={'25000+'} text={'PEPs and Sanctions profiles'} />
            <OurDataTemplate amount={'200+'} text={'Countries and territories covered'} />
        </section>

    )
}

//About the product section
export function SanctionsEvaders(){
    return(

    <section className=' bg-blue-50 p-4 pt-11 pb-11'>
        <FaConnectdevelop className='text-8xl bg-primary text-lime-100 p-2 mx-auto mb-11'/>
        <section className='mb-[200px] mx-auto max-w-[768px]'>
        <h1 className='mb-4 text-primary text-center text-4xl'>Network risks</h1>
        <h2 className='mb-4 text-3xl text-center text-slate-500'>Go beyond Sanction Lists</h2>
        <p className='text-xl font-thin'>Identify sanctions evaders and the network behind sanctioned entities with our sanctioned related data and suspected links.</p>       
        <Desktop/>
        </section>


        <FaRegIdBadge className='text-8xl bg-primary text-lime-100 p-2 mx-auto mb-11'/>
        <section className='mx-auto max-w-[768px] mb-[150px]'>
        <h1 className='mb-4 text-primary text-center text-4xl'>PEP profiles</h1>
        <h2 className='mb-4 text-3xl text-center text-slate-500'>Screen PEPs and Directors of State Owned Entities</h2>
        <p className='text-xl mt-11 mb-11 font-thin'>
           Our PEP data covers executives of State Owned Entities, Members of both national assemblies
           and local governments </p>      

       
           <ul className='text-xl'>
            <li className='flex flex-row gap-2 items-center'>
                <FaCheckCircle className='text-lime-500'/>
                <h2>Prime Ministers, Presidents, Royal Families </h2>       
            </li>
            <li className='flex flex-row gap-2 items-center'>
                <FaCheckCircle className='text-lime-500'/>
                <h2>Ministers, Executives of State Owned Entity, Civil Servants </h2>       
            </li>
            <li className='flex flex-row gap-2 items-center'>
                <FaCheckCircle className='text-lime-500'/>
                <h2>Members of National Assemblies</h2>       
            </li>
        </ul>


        
        </section>
        
      
        <section className='mb-[150px] mx-auto max-w-[768px]'>
        <h1 className='mb-4 text-primary text-center text-4xl'>Curated Intelligence about each entity</h1>
        <h2 className='mb-4 text-3xl text-center text-slate-500'>Understand context</h2>
        <TfiSearch className='text-8xl bg-primary text-lime-100 p-2 mx-auto mb-11'/>
        <p className='text-xl font-thin'>
            We use primary and secondary sources to curate information about people, vessels, and organisations to help you stay one step ahead.</p>       
        </section>





    </section>


    )



}
