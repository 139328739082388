import React, { useState, useEffect } from 'react';
import ResultsPage from '../../pages/result/result';
import { useNavigate } from 'react-router-dom';
import { Search } from '../../scripts/search'

function SearchBar({ placeholder, data, isOpen, isSearchButton, isCaseSideBar }) {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();




    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [entities, setEntities] = useState([]);


    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setSearchQuery(searchWord);

        const newFilter = data.filter((value) => {
            return value.Entity.toLowerCase().includes(searchWord);
        });

        setEntities(newFilter);
        console.log(newFilter)

    };

    const handleSearchClick = () => {
        setIsSearchClicked(true);
        console.log(isSearchClicked);
        navigate('/result', { state: { entities: entities } }); //Navigate to ResultsPage and pass filtered entities
    };

    const handleCreateCase = (entity) => {
        navigate('/company-profile', { state: { entity: entity } });
    };



    return (
       <>
           <Search placeholder={'Search entity'} 
           isOpen={isOpen}
           isSearchButton={isSearchButton}
           isCaseSideBar={isCaseSideBar}
           
           
           />
                {isSearchClicked && <ResultsPage entities={searchQuery} setSearchQuery={setSearchQuery} handleCreateCase={handleCreateCase} />}
            
        </>



    )
}
export default SearchBar;

