import React from "react";

export default function Footer({ title, text }) {


    const handleGoAboutUs = () => {
        window.location.href = '/about-us';
    };

    const navigateOurDataDashboard = () => {
        window.open("https://lookerstudio.google.com/reporting/be42cea8-5ea0-49e0-a6ee-9b20c29f33b7", "_blank")
    }


    function navigatetoTrello() {
        window.open("https://trello.com/b/CvHWr2Sx/knowyoursanctions-roadmap", "_blank"); // Replace with your desired URL
    }

    return (



        <footer className="border-t-2 flex justify-center p-11 bg-OffWhite ">
            <div>

                <ul className="flex flex-row gap-5 font-thin justify-center">
                    <li> <a className=" cursor-pointer" onClick={handleGoAboutUs}>About Us</a> </li>
                    <li> <a className=" cursor-pointer" onClick={navigateOurDataDashboard}>Our data</a> </li>
                    <li> <a className=" cursor-pointer" onClick={navigatetoTrello}>Roadmap</a> </li>
                </ul>

                <p className="text-sm mt-11">Copyrights 2023 KnowYourSanctions </p>
            </div>
        </footer>





    );
}
