import '../../../../style.scss';
import React from 'react';
import {FaConnectdevelop} from 'react-icons/fa'

function HeroBanner() {
  return (

    <>

      <div className='mb-11'>
        <div className='flex flex-row items-center gap-4 lg:gap-11'>
          <FaConnectdevelop className='text-6xl'/>
         
            <span>
            <h1 className='text-5xl sm:text-8xl font-thick'> Know Your Sanctions </h1>
            </span>
  
        </div>
          <p className='text-lg font-bold bg-green-200 rounded-xl w-fit p-1 sm:p-2 italic mt-4'> Sanctions  and PEP Screening Made Easy</p>
        </div>
    
     </>





  );
}

export default HeroBanner;
