import React from "react";
import '../../style.scss';

//The company result component retruns title and data of the company. 
//It is used by companyResult display each of the categories of data. 

export default function CompanyResult({ heading, data }) {
  const isEmptyArray = Array.isArray(data) && data.length === 0;
  const isWhitespace = typeof data === "string" && data.trim() === "";
  const hasEmptyString = Array.isArray(data) && data.includes("");


  const displayData = isEmptyArray || hasEmptyString || isWhitespace ? "-" : data;
  return (
    <>

      <div className='flex flex-col p-2'>
        <h1 className="font-semibold mb-2">{heading}</h1>

        {Array.isArray(displayData) ? (
          displayData.map((item, index) => (
            <p key={index} className="font-thin">{item}</p>
          ))
        ) : (
          <p className="font-thin">{displayData}</p>
        )}


      </div>

    </>
  );
};